export default {
  nav: 'container',
  ul: ['flex', 'flex-col', 'justify-end', 'list-none', 'sm:flex-row'].join(' '),
  li: ['mb-3', 'sm:ml-3', 'sm:mb-0', 'even:bg-gray-50', 'odd:bg-white'].join(' '),
  a: ({ isActive }) =>
    [
      'text-black',
      'font-bold',
      'inline-block',
      'rounded-full',
      'bg-yellow-400',
      'py-1',
      'px-3',
      isActive ? 'text-white' : 'hover:bg-yellow-500',
    ].join(' '),
  heroSelectionGrid: [
    'w-full',
    'grid',
    'grid-cols-3',
    'sm:grid-cols-4',
    'md:grid-cols-6',
    'lg:grid-cols-8',
    'xl:grid-cols-10',
    'mx-auto',
    'pt-4',
    'gap-4',
    // 'justify-evenly',
    // 'justify-items-center',
  ].join(' '),
  heroSelection: (hasHero) =>
    [
      'relative',
      // 'max-w-max',
      'aspect-w-1',
      'aspect-h-1',
      hasHero ? 'border-skyblue' : 'border-gray-300',
      'border-2',
      'rounded-xl',
      'shadow-md',
    ].join(' '),
  heroDisplayGrid: [
    'w-full',
    'h-full',
    'max-w-screen-md',
    'xl:max-w-screen-lg',
    'flex-auto',
    'lg:flex-1',
    'grid',
    'grid-cols-3',
    'sm:grid-cols-5',
    'md:grid-cols-7',
    'xl:grid-cols-8',
    'mx-auto',
    'py-2',
    'gap-2',
    // 'justify-evenly',
    // 'justify-items-center',
  ].join(' '),
  heroDisplay: (hasHero) =>
    [
      'relative',
      // 'max-w-max',
      'aspect-w-1',
      'aspect-h-1',
      hasHero ? 'border-skyblue' : 'border-gray-300',
      'border-2',
      'rounded-xl',
      'shadow-md',
    ].join(' '),
  heroDisplayImage: (hasHero) => [hasHero ? '' : 'filter grayscale', 'select-none', 'rounded-xl'].join(' '),
  underline: (color) =>
    [
      'w-full',
      'relative',
      'bg-gradient-to-r',
      color,
      // 'sm:to-transparent',
      // hasHero ? 'border-skyblue' : 'border-gray-300',
      // 'border-2',
      // 'rounded-xl',
      // 'shadow-md',
    ].join(' '),
  heroImage: (hasHero) =>
    [
      hasHero ? '' : 'filter grayscale',
      'select-none',
      'w-full',
      'h-full',
      'absolute',
      'inset-0',
      // 'object-cover',
    ].join(' '),
  weaponFlag: ['select-none', 'z-1', 'top-0', 'right-0', 'w-5/12'].join(' '),
  hoverLayer: (isHovered = false) =>
    [
      isHovered ? 'block' : 'hidden',
      'select-none',
      'rounded-xl',
      // 'top-0',
      'hero_selection_layer',
      'absolute',
      'w-full',
      'h-full',
      'bg-gray-800',
      'bg-opacity-50',
    ].join(' '),
  heroEvolutionButton: [
    'absolute',
    'rounded-full',
    'top-0',
    '-mt-1',
    '-mb-1',
    '-ml-1',
    'border-skyblue',
    'border-2',
    'left-0',
    'bg-gray-800',
    'w-10',
    'cursor-pointer',
    'h-10',
  ].join(' '),
  heroLimitBreakButton: [
    'absolute',
    'rounded-full',
    'w-10',
    'h-10',
    'left-0',
    'bottom-0',
    '-mb-2',
    '-ml-2',
    'border-skyblue',
    'border-2',
    'bg-gray-800',
    'cursor-pointer',
  ].join(' '),
  weaponLimitBreakButton: [
    'absolute',
    'rounded-full',
    'w-10',
    'h-10',
    'right-0',
    'bottom-0',
    '-mb-2',
    '-mr-2',
    'border-skyblue',
    'border-2',
    'bg-gray-800',
    'cursor-pointer',
  ].join(' '),
  limitBreakButtonText: [
    'select-none',
    'absolute',
    'w-full',
    'h-full',
    'text-lg',
    'pt-1',
    'font-press-start',
    'text-center',
    'text-white',
  ].join(' '),
  limitBreakButtonImg: ['absolute', 'top-0', 'left-0', 'select-none'].join(' '),
  displayLayer: (isHovered = false) =>
    [
      isHovered ? 'hidden' : 'block',
      'select-none',
      // 'top-0',
      'rounded-xl',
      'hero_display_layer',
      'absolute',
      'w-full',
      'h-full',
    ].join(' '),
  heroEvolutionText: [
    'select-none',
    'absolute',
    'top-0.5',
    'left-0.5',
    'font-level',
    'font-bold',
    'text-yellow-star',
    'xl:text-sm',
  ].join(' '),
  limitBreakText: (isHero) =>
    [
      'absolute',
      'flex flex-nowrap items-center',
      'select-none',
      'bottom-0',
      isHero ? 'left-0' : 'right-0',
      isHero ? 'ml-1' : 'mr-1',
      isHero ? 'flex-row' : 'flex-row-reverse',
      'w-1/2',
      '-mb-1',
      'xl:text-sm',
      'font-level',
      'font-bold',
      'text-white',
    ].join(' '),
  filterOption: ['p-2', 'cursor-pointer', 'flex-shrink-0'].join(' '),
  shareButton: (isEnabled = true) =>
    [
      'py-2',
      'px-4',
      isEnabled ? 'bg-green-button' : 'bg-gray-300',
      isEnabled ? 'border-green-border' : 'border-gray-600',
      'border-2',
      isEnabled ? 'text-black' : 'text-gray-100',
      'font-bold',
      'rounded-lg',
      isEnabled ? 'shadow-md' : null,
      isEnabled ? 'hover:bg-green-600 ' : null,
      'focus:outline-none',
      isEnabled ? null : 'cursor-not-allowed',
    ].join(' '),
  submitButton: (isProfileSubmitting) =>
    [
      'inline-flex',
      'items-center',
      'py-2',
      'px-4',
      !isProfileSubmitting ? 'bg-green-button' : 'bg-gray-300',
      !isProfileSubmitting ? 'border-green-border' : 'border-gray-600',
      'border-2',
      !isProfileSubmitting ? 'text-black' : 'text-gray-100',
      'font-bold',
      'rounded-lg',
      !isProfileSubmitting ? 'shadow-md' : null,
      !isProfileSubmitting ? 'hover:bg-green-600 ' : null,
      'focus:outline-none',
      !isProfileSubmitting ? null : 'cursor-not-allowed',
    ].join(' '),
  submitButtonSpinning: (isProfileSubmitting) =>
    ['animate-spin', 'h-5', 'w-5', 'mr-3', isProfileSubmitting ? null : 'hidden'].join(' '),
  howToUseBackground: ['bg-gradient-to-r from-gray-900 to-gray-700'].join(' '),
}
