import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.png'
import socialCard from '../images/social_card.png'
import { useIntl } from 'gatsby-plugin-intl'

const SEO = ({ title, description }) => {
  const intl = useIntl()
  const siteMetadata = {
    title: intl.formatMessage({ id: 'title' }),
    description: intl.formatMessage({ id: 'description' }),
    siteUrl: `https://guardiantalesprofile.com`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      defaultTitle={siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: description || siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title || siteMetadata.title,
        },
        {
          property: 'og:description',
          content: description || siteMetadata.description,
        },
        {
          property: 'og:url',
          content: siteMetadata.siteUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          property: 'og:image',
          content: `${socialCard}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: `${favicon}`,
        },
      ]}
    >
      {/* <script
        data-ad-client="ca-pub-5327915580283031"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      /> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
}

export default SEO
